module.exports = [{
      plugin: require('/home/andrew/dev/sites/andrewl/static-site/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/andrew/dev/sites/andrewl/static-site/node_modules/gatsby-plugin-fathom/gatsby-browser'),
      options: {"plugins":[],"trackingUrl":"analytics.andrewl.net","siteId":"MMQTX"},
    },{
      plugin: require('/home/andrew/dev/sites/andrewl/static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
